import React, { useState, useEffect } from 'react';
import { Activity, AlertTriangle, Bell, Clock, User, Settings } from 'lucide-react';

const Dashboard = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          window.location.href = '/login';
          return;
        }

        const response = await fetch('https://api.helloclone.ai/api/v1/me', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) throw new Error('Failed to fetch user data');
        const userData = await response.json();
        setUser(userData);
        setLoading(false);
      } catch (error) {
        console.error('Error:', error);
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  if (loading) {
    return (
      <div className="loading">Loading...</div>
    );
  }

  return (
    <div className="app-container">
      <nav className="sidebar">
        <h1 className="logo">HelloClone</h1>
        <div className="nav-items">
          <a href="/" className="nav-item active">
            <Activity className="w-5 h-5 mr-3" />
            Dashboard
          </a>
          <a href="/live-view" className="nav-item">
            <Clock className="w-5 h-5 mr-3" />
            Live View
          </a>
          <a href="/messages" className="nav-item">
            <Bell className="w-5 h-5 mr-3" />
            Messages
          </a>
          <a href="/settings" className="nav-item">
            <Settings className="w-5 h-5 mr-3" />
            Settings
          </a>
        </div>
      </nav>

      <main className="main-content">
        <header className="navbar">
          <h1>Welcome, {user?.full_name || 'User'}</h1>
          <div className="flex items-center space-x-4">
            <button className="btn-icon">
              <Bell />
            </button>
            <button className="btn-icon">
              <User />
            </button>
          </div>
        </header>

        <div className="stats-container">
          <div className="stat-box">
            <div className="stat-box-header">
              <div className="stat-icon-wrapper bg-blue-100">
                <Activity />
              </div>
              <div className="stat-box-content">
                <h3>Active Projects</h3>
                <div className="stat-number">3</div>
              </div>
            </div>
          </div>

          <div className="stat-box">
            <div className="stat-box-header">
              <div className="stat-icon-wrapper bg-yellow-100">
                <Clock />
              </div>
              <div className="stat-box-content">
                <h3>Pending Approvals</h3>
                <div className="stat-number">1</div>
              </div>
            </div>
          </div>

          <div className="stat-box">
            <div className="stat-box-header">
              <div className="stat-icon-wrapper bg-red-100">
                <AlertTriangle />
              </div>
              <div className="stat-box-content">
                <h3>Alerts</h3>
                <div className="stat-number">2</div>
              </div>
            </div>
          </div>
        </div>

        <section className="activity-section">
          <div className="activity-header">
            <h2>Recent Activity</h2>
          </div>
          <div className="activity-timeline">
            <div className="activity-item">
              <div className="timeline-marker bg-blue-100"></div>
              <div className="timeline-content">
                <h4>Website Redesign task updated</h4>
                <p>2 minutes ago</p>
              </div>
            </div>
            <div className="activity-item">
              <div className="timeline-marker bg-green-100"></div>
              <div className="timeline-content">
                <h4>Marketing campaign completed</h4>
                <p>1 hour ago</p>
              </div>
            </div>
            <div className="activity-item">
              <div className="timeline-marker bg-yellow-100"></div>
              <div className="timeline-content">
                <h4>New feature development started</h4>
                <p>3 hours ago</p>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Dashboard;
