import React, { useState, useEffect } from 'react';
import { Activity, AlertTriangle, Check, Clock, Filter, Search } from 'lucide-react';

const LiveView = () => {
  const [tasks, setTasks] = useState([
    {
      id: 1,
      name: 'Website Redesign',
      description: 'Update the company website with new branding',
      status: 'in progress',
      progress: 65,
      assignee: 'Frontend Agent',
      mode: 'AI',
      eta: '2 hours'
    },
    {
      id: 2,
      name: 'Marketing Campaign',
      description: 'Q1 Marketing Campaign execution',
      status: 'completed',
      progress: 100,
      assignee: 'Marketing Agent',
      mode: 'AI',
      eta: '-'
    },
    {
      id: 3,
      name: 'New Feature Development',
      description: 'Implement new product features',
      status: 'pending',
      progress: 0,
      assignee: 'Development Team',
      mode: 'Human',
      eta: 'TBD'
    }
  ]);
  const [filter, setFilter] = useState('all');
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);

  // Filter and search tasks
  const filteredTasks = tasks.filter(task => {
    const matchesFilter = filter === 'all' || task.status.toLowerCase() === filter;
    const matchesSearch = task.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                         task.description.toLowerCase().includes(searchQuery.toLowerCase());
    return matchesFilter && matchesSearch;
  });

  const getStatusIcon = (status) => {
    switch(status.toLowerCase()) {
      case 'completed':
        return <Check className="text-green-500" />;
      case 'in progress':
        return <Activity className="text-blue-500" />;
      case 'pending':
        return <Clock className="text-yellow-500" />;
      default:
        return <AlertTriangle className="text-gray-500" />;
    }
  };

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-gray-900">Live View</h1>
        <div className="flex items-center space-x-4">
          {/* Search Bar */}
          <div className="relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
            <input
              type="text"
              placeholder="Search tasks..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          
          {/* Filter Dropdown */}
          <select
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            className="px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="all">All Tasks</option>
            <option value="in progress">In Progress</option>
            <option value="completed">Completed</option>
            <option value="pending">Pending</option>
          </select>
        </div>
      </div>

      {/* Tasks Grid */}
      {loading ? (
        <div className="flex justify-center items-center h-64">
          <div className="text-lg text-gray-500">Loading tasks...</div>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredTasks.map(task => (
            <div key={task.id} className="bg-white rounded-lg shadow">
              <div className="p-6">
                <div className="flex items-start justify-between">
                  <div>
                    <h3 className="text-lg font-medium text-gray-900">{task.name}</h3>
                    <p className="mt-1 text-sm text-gray-500">{task.description}</p>
                  </div>
                  {getStatusIcon(task.status)}
                </div>
                
                {/* Progress Bar */}
                <div className="mt-4">
                  <div className="flex justify-between items-center mb-1">
                    <span className="text-sm font-medium text-gray-700">Progress</span>
                    <span className="text-sm font-medium text-gray-700">{task.progress}%</span>
                  </div>
                  <div className="w-full bg-gray-200 rounded-full h-2">
                    <div
                      className="bg-blue-600 h-2 rounded-full transition-all duration-300"
                      style={{ width: `${task.progress}%` }}
                    ></div>
                  </div>
                </div>

                {/* Task Details */}
                <div className="mt-4 space-y-2">
                  <div className="flex justify-between text-sm">
                    <span className="text-gray-500">Assigned to:</span>
                    <span className="text-gray-900">{task.assignee}</span>
                  </div>
                  <div className="flex justify-between text-sm">
                    <span className="text-gray-500">Mode:</span>
                    <span className={`px-2 py-1 rounded-full text-xs ${
                      task.mode === 'AI' ? 'bg-purple-100 text-purple-800' : 'bg-gray-100 text-gray-800'
                    }`}>
                      {task.mode}
                    </span>
                  </div>
                  <div className="flex justify-between text-sm">
                    <span className="text-gray-500">ETA:</span>
                    <span className="text-gray-900">{task.eta}</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LiveView;
